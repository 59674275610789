import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDayOfYear } from 'date-fns';
import _ from 'lodash';
import { countByRules } from 'services/Comercial/AnyMember';
import { createPromotionProfile, getProfilingById, updatePromotionProfile, updateStatusPromotionProfile } from 'services/Comercial/Profiling';
const stateName = 'promotionProfile';

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 *
 * Retorna el estado local
 * @param {Object} state
 * @returns {Object}
 */
const localState = (state, key = null) => {
    return key ? state[stateName][key] : state[stateName];
}

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 * Procesa los datos retornados del servidor, para que puedan ser usados por el formulario
 *
 * @param {Object} data
 * @returns {Object}
 */
const processLoadResult = data => {
    return {
        name: data.name,
        short_description: data.description,
        genre: data.sex,
        age: [data.age_start, data.age_end],
        //birthday: [data.birthday_end, age.birthday_end],
        birthday: [
          new Date(`${data.birthday_start} 0:0:0`),
          new Date(`${data.birthday_end} 0:0:0`)],
        country: data.countries,
        city: data.cities,
        is_new: data.is_new,
        start_registration_date: data.start_registration_date ? new Date(data.start_registration_date) : null,
        end_registration_date: data.end_registration_date ? new Date(data.end_registration_date) : null,
        new_product: data.newProducts,
        is_active: data.is_active_user,
        start_purchase_date: data.start_purchase_date ? new Date(data.start_purchase_date) : null,
        end_purchase_date: data.end_purchase_date ? new Date(data.end_purchase_date) : null,
        active_product: data.activeProducts,
        is_renovation: data.is_renovation,
        start_renovation_date: data.start_renovation_date ? new Date(data.start_renovation_date) : null,
        end_renovation_date: data.end_renovation_date ? new Date(data.end_renovation_date) : null,
        renovation_product: data.renovationProduct,
        is_inactive: data.is_inactive,
        start_inactive_date: data.start_inactive_date ? new Date(data.start_inactive_date) : null,
        end_inactive_date: data.end_inactive_date ? new Date(data.end_inactive_date) : null,
        inactive_product: data.inactiveProducts,
    }
}

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 * Procesa los datos del estado para ser enviados a la API
 *
 * @param {Object} data
 * @returns {Object}
 */
const processDraft = (data, isMethodGet = false) => {
    const transformData = (v) => isMethodGet ? (v ? 1 : 0) : v;
    return {
        //step 1
        name: data.name,
        description: data.short_description,
        //step 2
        // sex: data.genre?.id ?? data.genre,
        sex: data.genre?.map(g => g.id),
        age_start: data.age[0],
        age_end: data.age[1],
        birthday_start: getDayOfYear(data.birthday[0]),
        birthday_end: getDayOfYear(data.birthday[1]),
        countries: data.country?.map(c => c.id),
        cities: data.city?.map(c => c.id),
        //step 3
        is_new: transformData(data.is_new),
        start_registration_date: data.start_registration_date,
        end_registration_date: data.end_registration_date,
        is_spontaneous_user: transformData(data.type_user?.findIndex(t => t.value === 'spontaneous') >= 0),
        is_referred_user: transformData(data.type_user?.findIndex(t => t.value === 'spontaneous') >= 0),
        is_guest_user: transformData(data.type_user?.findIndex(t => t.value === 'guest') >= 0),
        new_products: data.new_product?.map(p => p.id),

        is_active_user: transformData(data.is_active),
        start_purchase_date: data.start_purchase_date,
        end_purchase_date: data.end_purchase_date,
        active_products: data.active_product?.map(p => p.id),

        is_renovation: transformData(data.is_renovation),
        start_renovation_date: data.start_renovation_date,
        end_renovation_date: data.end_renovation_date,
        renovation_product: data.renovation_product?.map(p => p.id),

        is_inactive: transformData(data.is_inactive),
        start_inactive_date: data.start_inactive_date,
        end_inactive_date: data.end_inactive_date,
        inactive_products: data.inactive_product?.map(p => p.id),
    };
}

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 *
 * Función que consulta el numero de AnyMerbers (mermber, customer y crm_lead) a la API de products
 */
export const getNAnyMermbersByRulesAsync = createAsyncThunk(
    'any-members/count/by/rules',
    async (_, thunkAPI) => {
        try {
            const response = await countByRules(processDraft(localState(thunkAPI.getState()).draft, true));
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
);

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 *
 * Función que envía los datos a la API para la creación del perfilamiento
 */
export const createAsync = createAsyncThunk(
    'promotion_profile/create',
    async (_, thunkAPI) => {
        try {
            const response = await createPromotionProfile(processDraft(localState(thunkAPI.getState()).draft));
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    },
    {
        condition: (_, { getState, extra }) => {
            return !['created', 'loading'].includes(localState(getState()).draftStatus);
        }
    }
);

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 *
 * Función que envía los datos a la API para la actualización del perfilamiento
 */
export const updateAsync = createAsyncThunk(
    'promotion_profile/update',
    async (_, thunkAPI) => {
        try {
            const response = await updatePromotionProfile(localState(thunkAPI.getState()).id, processDraft(localState(thunkAPI.getState()).draft));
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    },
    {
        condition: (_, { getState, extra }) => {
            return !['loading'].includes(localState(getState()).draftStatus) && !!localState(getState()).id;
        }
    }
);

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 *
 * Función que envía los datos a la API para la creación del perfilamiento
 */
export const laodDraftByIdAsync = createAsyncThunk(
    'promotion_profile/load/draft',
    async (id, thunkAPI) => {
        try {
            const response = await getProfilingById(id);
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    },
    {
        condition: (id, { getState, extra }) => {
            return localState(getState()).id !== id;
        }
    }
);

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 *
 * Función que envía los datos a la API para la actualización del perfilamiento
 */
export const updateStatusAsync = createAsyncThunk(
    'promotion_profile/update/status',
    async ({ id, status, reload }, thunkAPI) => {
        try {
            const response = await updateStatusPromotionProfile(id, status);

            if (response.data.status === "error") {
                return thunkAPI.rejectWithValue(response.data.message[0].message)
            }

            return response;
        } catch (err) {
            console.log("err", err);
            return thunkAPI.rejectWithValue(err)
        }
    },
    {
        condition: ({ id, status }, { getState, extra }) => {
            return localState(getState()).promotionProfileStatus.findIndex(v => v.id == id && v.updating) === -1;
        }
    }
);

/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 *
 * Estado inicial
 */
export const initialState = {
    id: null,
    draft: {
        name: '',
        short_description: '',
        genre: [],
        age: [10, 90],
        birthday: [1, 365],
        country: [],
        city: [],
        start_registration_date: null,
        start_purchase_date: null,
        start_renovation_date: null,
        start_inactive_date: null,
        end_registration_date: null,
        end_purchase_date: null,
        end_renovation_date: null,
        end_inactive_date: null,
    },
    responseDraft: null,
    activeStep: 0,
    loadDraftStatus: 'idle',
    draftStatus: 'idle',
    promotionProfileStatus: [],
    nAnyMermbersByRules: 0,
    nAnyMermbersByRulesStatus: 'idle',
    nAnyMermbersByRulesResponse: null,
    errorMessage: null
};
/**
 * @author Carlos A. Ortiz <carlos.ortiz@bodytechcorp.com>
 *
 */
export const promotionProfileSlice = createSlice({
    name: 'promotion_profile',
    initialState,
    reducers: {
        resetDraft: (state) => {
            state.id = initialState.id;
            state.draft = initialState.draft;
            state.draftStatus = initialState.draftStatus;
            state.countUsersByDraft = initialState.countUsersByDraft;
        },
        setDraft: (state, action) => {
            state.draft = action.payload;
        },
        setDraftStep: (state, action) => {
            state.draft = {
                ...state.draft,
                ...action.payload
            };
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        addPromotionProfileStatus: (state, action) => {
            state.promotionProfileStatus = [
                ...state.promotionProfileStatus.filter(v => v.id !== action.payload.id),
                { id: action.payload.id, status: action.payload.status }
            ];
        },
        setPromotionProfileStatus: (state, action) => {
            state.promotionProfileStatus = action.payload
                .filter(({ id, status }) => ((typeof id !== 'undefined') && (typeof status !== 'undefined')))
                .map(({ id, status }) => ({ id, status }))
                ;
        },
        setMessageError: (state, action) => {
            state.errorMessage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Consultar perfilamiento
            .addCase(laodDraftByIdAsync.pending, (state, action) => {
                state.loadDraftStatus = 'loading';
            })
            .addCase(laodDraftByIdAsync.fulfilled, (state, action) => {
                const resData = action.payload.data;
                const statusResposne = resData.status === 'success' ? 'loaded' : 'error';
                state.responseDraft = action.payload.data
                if (statusResposne) {
                    state.draft = processLoadResult(resData.data)
                    state.id = resData.data.id
                    state.loadDraftStatus = statusResposne
                }
            })
            .addCase(laodDraftByIdAsync.rejected, (state, action) => {
                state.loadDraftStatus = 'error';
                state.responseDraft = action.payload
            })
            // Crear perfilamiento
            .addCase(createAsync.pending, (state, action) => {
                state.draftStatus = 'loading';
            })
            .addCase(createAsync.fulfilled, (state, action) => {
                state.draftStatus = 'created';
                state.responseDraft = action.payload.data
                state.id = action.payload.data.data.id
            })
            .addCase(createAsync.rejected, (state, action) => {
                state.draftStatus = 'error';
                state.responseDraft = action.payload
            })
            // Actualizar perfilamiento
            .addCase(updateAsync.pending, (state, action) => {
                state.draftStatus = 'loading';
            })
            .addCase(updateAsync.fulfilled, (state, action) => {
                state.draftStatus = 'updated';
                state.responseDraft = action.payload.data
                state.id = action.payload.data.data.id
            })
            .addCase(updateAsync.rejected, (state, action) => {
                state.draftStatus = 'error';
                state.responseDraft = action.payload
            })
            // Consultar No. de AnyMermbers
            .addCase(getNAnyMermbersByRulesAsync.pending, (state, action) => {
                state.nAnyMermbersByRulesStatus = 'loading';
            })
            .addCase(getNAnyMermbersByRulesAsync.fulfilled, (state, action) => {
                state.nAnyMermbersByRulesStatus = 'created';
                state.nAnyMermbersByRulesResponse = action.payload.data
                state.nAnyMermbersByRules = action.payload.data.data.count
            })
            .addCase(getNAnyMermbersByRulesAsync.rejected, (state, action) => {
                state.nAnyMermbersByRulesStatus = 'error';
                state.nAnyMermbersByRulesResponse = action.payload
            })
            // Actualizar el estado de un perfilamiento
            .addCase(updateStatusAsync.pending, (state, action) => {
                const { id } = action.meta.arg;

                state.promotionProfileStatus = state.promotionProfileStatus.map(pPStatus => ({
                    ...pPStatus,
                    ...(pPStatus.id === id ? { updating: true } : {})
                }))
            })
            .addCase(updateStatusAsync.fulfilled, (state, action) => {
                const { id, status, reload } = action.meta.arg;

                state.promotionProfileStatus = state.promotionProfileStatus.map(pPStatus => ({
                    ...pPStatus,
                    ...(pPStatus.id === id ? { status, updating: false } : {})
                }))
            })
            .addCase(updateStatusAsync.rejected, (state, action) => {
                const { id, status, reload } = action.meta.arg;

                state.errorMessage = action.payload;

                state.promotionProfileStatus = state.promotionProfileStatus.map(pPStatus => ({
                    ...pPStatus,
                    ...(pPStatus.id === id ? { updating: false } : {})
                }))
            })
    },
});

export const { resetDraft, setDraft, setDraftStep, setActiveStep, addPromotionProfileStatus, setPromotionProfileStatus, setMessageError } = promotionProfileSlice.actions;

//selectors
export const selectState = (state) => localState(state);
export const selectDraft = (state) => localState(state).draft;
export const selectActiveStep = (state) => localState(state).activeStep;
export const selectId = (state) => localState(state).id;
export const selectDraftStatus = (state) => localState(state).draftStatus;
export const selectLoadDraftStatus = (state) => localState(state).loadDraftStatus;
export const selectResponseDraft = (state) => localState(state).responseDraft;

export const selectNAnyMermbersByRules = (state) => localState(state).nAnyMermbersByRules
export const selectNAnyMermbersByRulesStatus = (state) => localState(state).nAnyMermbersByRulesStatus
export const selectNAnyMermbersByRulesResponse = (state) => localState(state).nAnyMermbersByRulesResponse

export const isLoadDraftLoading = (state) => selectLoadDraftStatus(state) === 'loading';
export const isDataLoaded = (state) => selectLoadDraftStatus(state) === 'loaded';
export const dataWithError = (state) => selectLoadDraftStatus(state) === 'error';

export const isDraftLoading = (state) => selectDraftStatus(state) === 'loading';
export const isCreated = (state) => selectDraftStatus(state) === 'created';
export const isUpdated = (state) => selectDraftStatus(state) === 'updated';
export const withError = (state) => selectDraftStatus(state) === 'error';
export const errorMessage = (state) => localState(state).errorMessage;

export const getPromotionProfileStatus = (state, id) => _.find(localState(state).promotionProfileStatus, (v) => (v.id === id));
export const isPromotionProfileUpdatingStatus = (state, id) => _.find(localState(state).promotionProfileStatus, (v) => (v.id === id));

export const canLoadDraft = (state) => (
    !isLoadDraftLoading(state) &&
    selectId(state)
);
export const canCreate = (state) => (
    !isDraftLoading(state) &&
    !selectId(state)
);
export const canUpdate = (state) => (
    !isDraftLoading(state) &&
    !selectId(state)
);



export default promotionProfileSlice.reducer;
