import { Cookies } from "react-cookie";
import { loginService, signUpService, profileService } from "../services/auth";

export const LOGIN_STATE_TOGGLE = "auth/LOGIN_STATE_TOGGLE";
export const LOGIN = "auth/LOGIN";
export const SIGN_UP_STATE_TOGGLE = "auth/SIGN_UP_STATE_TOGGLE";
export const SIGN_UP = "auth/SIGN_UP";
export const LOGOUT = "auth/LOGOUT";
export const SAVE_SIGNUP = "auth/SAVE_SIGNUP";
export const CHANGE_VENUE_PROFILE = "auth/CHANGE_VENUE_PROFILE";
export const CHANGE_CHECK_IS_VIRTUAL = "auth/CHANGE_CHECK_IS_VIRTUAL";
export const CHANGE_CHECK_IS_NOT_VIRTUAL = "auth/CHANGE_CHECK_IS_NOT_VIRTUAL";
export const GET_INFORMATION_USER = "auth/GET_INFORMATION_USER";
export const SET_DEFAULT_VENUE = "auth/SET_DEFAULT_VENUE";
export const SET_VENUES_USER = "auth/SET_VENUES_USER";
export const SET_BRAND_USER = "auth/SET_BRAND_USER";
export const SET_SHOULD_IS_VIRTUAL = "auth/SET_SHOULD_IS_VIRTUAL";
export const SET_COUNTRY_ID = "auth/SET_COUNTRY_ID";
export const UPDATE_PHOTO = "auth/UPDATE_PHOTO";
export const CHANGE_CHECK_APPLY_SURVEY = "auth/CHANGE_CHECK_APPLY_SURVEY";

const cookies = new Cookies();
const currentUser = cookies.get("user") || {};
const currentUserRestrictions =  cookies.get("userRestrictions") || [];
const currentVenueUser = localStorage.getItem("venueUser")
  ? JSON.parse(localStorage.getItem("venueUser"))
  : 0;
const currentBrandId = localStorage.getItem("brand_id") || 1;
const currentCompanyId = localStorage.getItem("company_id") || 1;
const initialState = {
  isUserAuthenticated: !!(cookies.get('user') && currentUser.organizationDefaultId),
  isLoggingIn: false,
  isSigningUp: false,
  shouldIsVirtual: false,
  userEmail: currentUser.userEmail,
  userType: currentUser.userType ? currentUser.userType : 0,
  userName: currentUser.userName,
  userNameRole: currentUser.userNameRole,
  userId: currentUser.userId,
  userProfileName: currentUser.userProfileName,
  userProfileId: currentUser.userProfileId,
  userStar: currentUser.userStar,
  userDocument: currentUser.userDocument,
  apply_manual_discount: currentUser.apply_manual_discount,
  change_password: currentUser.change_password,
  restrictions: currentUserRestrictions,
  crmDealAssign: currentUser.crmDealAssign,
  loadDeals: currentUser.loadDeals,
  hasCondonationIssues: currentUser.hasCondonationIssues,
  availableBrands:
    localStorage.getItem('brands') && localStorage.getItem('brands') !== 'undefined'
      ? JSON.parse(localStorage.getItem('brands'))
      : [],
  venuesProfile: [],
  // localStorage.getItem("venuesProfile") &&
  // localStorage.getItem("venuesProfile") !== "undefined"
  //   ? JSON.parse(localStorage.getItem("venuesProfile"))
  //   : [],
  companyId: currentCompanyId,
  brandId: currentBrandId,
  companyDefaultId: currentUser.companyDefaultId || 1,
  companyBrandId: currentUser.companyBrandId || 1,
  organizationDefaultId: currentUser.organizationDefaultId,
  venueNameDefaultProfile:
    currentVenueUser.venueNameDefaultProfile || currentUser.venueNameDefaultProfile,
  venueIdDefaultProfile: currentVenueUser.venueIdDefaultProfile,
  venueCityNameDefault: currentVenueUser.venueCityNameDefault,
  venueCityIdDefault: currentVenueUser.venueCityIdDefault,
  venueCountryIdDefault: currentVenueUser.venueCountryIdDefault,
  venueCategoryDefaultProfile: currentVenueUser.venueCategoryDefaultProfile,
  isVirtual: localStorage.getItem('is_virtual') ? Number(localStorage.getItem('is_virtual')) : 0,
  permissions: [],
  photo: currentUser.photo,
  signUpData: {
    email: "",
    password: "",
    store: "",
  },
  survey: false
};
const authModule = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STATE_TOGGLE:
      return {
        ...state,
        ...action.payload,
        isLoggingIn: !state.isLoggingIn,
      };
    case UPDATE_PHOTO:
      return {
        ...state,
        photo: action.payload,
      };
    case LOGIN:
      return {
        ...state,
        ...action.payload,
        isUserAuthenticated: true,
        isLoggingIn: false,
      };

    case SIGN_UP_STATE_TOGGLE:
      return {
        ...state,
        isSigningUp: !state.isSigningUp,
        ...action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        ...action.payload,
        isUserAuthenticated: false,
        userEmail: null,
        userType: 0,
        userId: null,
        storeId: null,
      };

    case SAVE_SIGNUP:
      return {
        ...state,
        signUpData: action.payload,
      };

    case CHANGE_VENUE_PROFILE:
      return {
        ...state,
        ...action.payload,
      };

    case GET_INFORMATION_USER:
      return {
        ...state,
        permissions: action.payload,
      };

    case SET_DEFAULT_VENUE:
      return {
        ...state,
        ...action.payload,
      };

    case SET_VENUES_USER:
      return {
        ...state,
        venuesProfile: action.payload,
      };

    case SET_BRAND_USER:
      return {
        ...state,
        ...action.payload,
      };

    case CHANGE_CHECK_IS_VIRTUAL:
      return {
        ...state,
        isVirtual: 1,
        ...action.payload,
      };

    case CHANGE_CHECK_IS_NOT_VIRTUAL:
      return {
        ...state,
        isVirtual: 0,
        ...action.payload,
      };

    case SET_SHOULD_IS_VIRTUAL:
      return {
        ...state,
        shouldIsVirtual: action.payload,
      };

    case SET_COUNTRY_ID: {
      return {
        ...state,
        venueCountryIdDefault: action.payload,
      };
    }

    case CHANGE_CHECK_APPLY_SURVEY: {
      return {
        ...state,
        survey: action.payload
      }
    }

    default:
      return state;
  }
};

/**
 * Calls the loginService and executes the given callback
 * @param {string} email user email
 * @param {string} pass user password
 * @param {function} onLogin callback to run after login success
 * @param {function} onError callback to run after login error
 */
export const logIn = (email, pass, onLogin, onError, setIsViewOtp, handleReload, setUuidUser = () => {}) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_STATE_TOGGLE })
    loginService(email, pass, setIsViewOtp, setUuidUser)
      .then(({ data }) => {
        logInActive(data, onLogin, dispatch)
        handleReload()
      })
      .catch((err) => {
        console.log(err)
        onError(err)
        dispatch({ type: LOGIN_STATE_TOGGLE })
      })
  }
}

export const logInActive = (data, onLogin, dispatch) => {
  const venueMain = data?.venues?.find((venue) => venue?.is_main === 1)
  let venueUser = {
    venueCityNameDefault:
      data.venues && data.venues.length !== 0
        ? data.venues[0].city_name
        : '',

    venueCityIdDefault:
      data.venues && data.venues.length !== 0
        ? data.venues[0].city_id
        : 0,
    venueNameDefaultProfile:
      data.venues && data.venues.length !== 0 ? data.venues[0].name : '',
    venueIdDefaultProfile:
      data.venues && data.venues?.length !== 0 ? data.venues[0].id : null,
    venueCategoryDefaultProfile:
      data.venues && data.venues?.length !== 0
        ? data.venues[0].category_id
        : null,
    venueCountryIdDefault:
      data.venues && data.venues?.length !== 0
        ? data.venues[0].country_id
        : 1,
  }

  if (venueMain !== undefined) {
    localStorage.setItem('venueMain', JSON.stringify(venueMain))
    venueUser = {
      ...venueUser,
      venueCityIdDefault: venueMain.city_id,
      venueCityNameDefault: venueMain.city_name,
      venueIdDefaultProfile: venueMain.id,
      venueNameDefaultProfile: venueMain.name,
      venueCategoryDefaultProfile: venueMain.category_id,
    }
  }

  if (data.is_virtual === true) {
    venueUser = {
      ...venueUser,
      venueCityIdDefault: null,
      venueCityNameDefault: null,
      // venueCountryIdDefault: null,
      venueIdDefaultProfile: null,
      venueNameDefaultProfile: null,
    }
  }

  const user = {
    userEmail: data.email,
    userName: data.first_name + ' ' + data.last_name,
    userType: (data.is_medical && Number(data.role_id) != 196) ? 3 : Number(data.role_id),
    userId: data.id,
    userCreate: data.created_at,
    userNameRole: data.role_name,
    userProfileName: data.first_name,
    userFirstName: data.first_name,
    userLastName: data.last_name,
    userProfileId: data.role_id,
    userStar: data.ranking,
    companyDefaultId: data.companyId,
    organizationDefaultId: data.companyOrganization,
    availableBrands: data.brands,
    brandId: data.brandId,
    userDocument: data.document_number,
    companyId: data.companyId,
    shouldIsVirtual: data.is_virtual,
    isVirtual: data.is_virtual === true ? 1 : 0,
    apply_manual_discount: data.set_manual_discount,
    change_password: data?.change_password,
    // restrictions: data?.restrictions,
    crmDealAssign: data?.reassign_deals,
    loadDeals: data?.load_deals,
    hasCondonationIssues: data?.has_condonation_issues,
    ...venueUser,
  }

  const venuesFilter =
    data && data.venues && data.venues.length > 0 ? data.venues : []

  localStorage.setItem('userFirstName', user.userFirstName)
  localStorage.setItem('userLastName', user.userLastName)
  localStorage.setItem('venueUser', JSON.stringify(venueUser))
  localStorage.setItem('venuesProfile', JSON.stringify(venuesFilter))
  localStorage.setItem('theme_user', JSON.stringify(Number(data.role_id)))
  localStorage.setItem('is_virtual', data.is_virtual === true ? 1 : 0)
  cookies.set('user', user, { path: '/' })
  cookies.set('userRestrictions', data?.restrictions, { path: '/' })
  onLogin()
  dispatch({
    type: LOGIN,
    payload: {
      ...user,
      restrictions: data?.restrictions,
      venuesProfile: venuesFilter,
      permissions:
        data.permissions && data.permissions.length === 0
          ? []
          : data.permissions && data.permissions.module_groups
            ? data.permissions.module_groups
            : []
    }
  })
  dispatch({ type: SIGN_UP_STATE_TOGGLE, isSigningUp: false })
}

/**
 * Logs the use in withou the api call
 */
export const logInDirectly = () => (dispatch) => {
  dispatch({ type: LOGIN });
};

/**
 * Creates a new user and then logs the new user in
 * @param {Object} signUpData sign up forms data
 */
export const signUp = (signUpData, onSignUp, onError) => {
  return (dispatch) => {
    dispatch({ type: SIGN_UP_STATE_TOGGLE });

    signUpService(signUpData)
      .then((res) => {
        if (res.data.error_code === 202) {
          onError("");
          dispatch({ type: SIGN_UP_STATE_TOGGLE });
        }
        if (res.status === 200) {
          onSignUp(
            signUpData.email,
            signUpData.password,
            () => false,
            () => false
          );
        }
      })
      .catch((err) => {
        onError(err);
        dispatch({ type: SIGN_UP_STATE_TOGGLE });
      });
  };
};

/**
 * Deletes the token cookie and logs out the user
 */
export const logOut = () => (dispatch) => {
  cookies.remove("user", { path: "/" });
  cookies.remove("userRestrictions", { path: "/" });
  localStorage.clear();
  dispatch({
    type: LOGOUT,
  });
};

export const updatePhotoProfile = (photo) => (dispatch) => {
  dispatch({
    type: UPDATE_PHOTO,
    payload: photo,
  });
};

/**
 * Logs the use in withou the api call
 */
export const getProfileInformation =
  (onFinish, isToFetchVenue = false) =>
    (dispatch) => {
      profileService()
        .then(({ data }) => {
          if (data.data && data.data.permissions) {
            if (isToFetchVenue) {
              const venuesInf = data?.data?.venues ? data?.data?.venues : []
              const venueUser = {
                venueCityNameDefault: venuesInf[0]?.city_name || null,
                venueCityIdDefault: venuesInf[0]?.city_id || null,
                venueNameDefaultProfile: venuesInf[0]?.name || null,
                venueIdDefaultProfile: venuesInf[0]?.id || null,
                venueCountryIdDefault: venuesInf[0]?.country_id || 1
              }

              // localStorage.setItem("venueUser", JSON.stringify(venueUser));

              // dispatch({
              //   type: SET_DEFAULT_VENUE,
              //   payload: venueUser,
              // });

              dispatch({
                type: SET_VENUES_USER,
                payload: venuesInf
              })

              return
            }

            localStorage.setItem('is_virtual', data.data.is_virtual ? 1 : 0)
            const permissionInf =
              data?.data && data?.data?.permissions && data?.data?.permissions.length === 0
                ? []
                : data?.data?.permissions.module_groups
            const venuesInf =
              data?.data && data?.data?.venues && data?.data?.venues?.length === 0
                ? []
                : data?.data?.venues
            const objectPassword = { change_password: data?.data?.change_password }
            if (permissionInf.length > 0) {
              dispatch({
                type: SET_SHOULD_IS_VIRTUAL,
                payload: data?.data?.is_virtual
              })
              dispatch({
                type: GET_INFORMATION_USER,
                payload: permissionInf
              })
              dispatch({
                type: LOGIN,
                payload: {
                  ...objectPassword
                }
              })
              dispatch({
                type: SET_VENUES_USER,
                payload: venuesInf
              })
              onFinish()
              return
            }
          }
          cookies.remove('user', { path: '/' })
          cookies.remove("userRestrictions", { path: "/" });
          localStorage.clear()
          dispatch({
            type: LOGOUT
          })
          onFinish()
        })
        .catch((err) => {
          cookies.remove('user', { path: '/' })
          cookies.remove("userRestrictions", { path: "/" });
          // localSts
          localStorage.clear()
          dispatch({
            type: LOGOUT
          })
          onFinish()
        })
    }

export const fetchDataVenuesProfile = (onError) => (dispatch) => {
  // return;
  profileService()
    .then(({ data }) => {
      if (data.data) {
        const venuesInf = data?.data?.venues ? data?.data?.venues : [];

        const venueUser = {
          venueCityNameDefault: venuesInf[0]?.city_name || null,
          venueCityIdDefault: venuesInf[0]?.city_id || null,
          venueNameDefaultProfile: venuesInf[0]?.name || null,
          venueIdDefaultProfile: venuesInf[0]?.id || null,
          venueCountryIdDefault: venuesInf[0]?.country_id || 1,
          venueCategoryDefaultProfile: venuesInf[0]?.category_id || null
        }

        localStorage.setItem("venueUser", JSON.stringify(venueUser));

        dispatch({
          type: SET_DEFAULT_VENUE,
          payload: venueUser,
        });

        dispatch({
          type: SET_VENUES_USER,
          payload: venuesInf,
        });
      }

      // onError("Este usuario no tiene sedes asignadas a esta marca");
    })
    .catch((err) => {
      cookies.remove("user", { path: "/" });
      cookies.remove("userRestrictions", { path: "/" });
      localStorage.clear();
      dispatch({
        type: LOGOUT,
      });
    });
};

/**
 * Saves the signUpForm state in the store
 * @param {Object} payload form data
 */
export const saveSignUpData = (payload) => (dispatch) => {
  dispatch({
    type: SAVE_SIGNUP,
    payload,
  });
};

export const changeVenueDefault = (payload) => (dispatch) => {
  const currentVenueUser = localStorage.getItem("venueUser")
    ? JSON.parse(localStorage.getItem("venueUser"))
    : {};
  const modifyDataUser = { ...currentVenueUser, ...payload };
  localStorage.setItem("venueUser", JSON.stringify(modifyDataUser));

  dispatch({
    type: CHANGE_VENUE_PROFILE,
    payload,
  });
};

export const changeBrandIdDefault = (payload) => (dispatch) => {
  localStorage.setItem("brand_id", payload.id);
  localStorage.setItem("company_id", payload.uuid_company);

  const payloadSend = {
    brandId: payload.id,
    companyId: payload.uuid_company,
  };

  dispatch({
    type: SET_BRAND_USER,
    payload: payloadSend,
  });
};

export const changeCheckIsVirtualUser = (value) => (dispatch) => {
  profileService()
    .then(({ data }) => {
      if (data.data && data.data.permissions) {
        const venues =
          data?.data && data?.data?.venues && data?.data?.venues?.length === 0
            ? []
            : data?.data?.venues

        if (value) {
          localStorage.setItem('is_virtual', value ? 1 : 0)
          const venueUser = {
            venueCityNameDefault: null,
            venueCityIdDefault: null,
            venueNameDefaultProfile: null,
            venueIdDefaultProfile: null,
            venueCountryIdDefault: venues.country_id ? venues[0]?.country_id : 1
          }

          localStorage.setItem('venueUser', JSON.stringify(venueUser))
          dispatch({
            type: CHANGE_CHECK_IS_VIRTUAL,
            payload: venueUser
          })
          return
        }

        if (venues && venues.length > 0) {
          localStorage.setItem('is_virtual', value ? 1 : 0)

          const venueUser = {
            venueCityNameDefault: venues[0].city_name,
            venueCityIdDefault: venues[0].city_id,
            venueNameDefaultProfile: venues[0].name,
            venueIdDefaultProfile: venues[0].id,
            venueCountryIdDefault: venues[0].country_id
          }

          localStorage.setItem('venueUser', JSON.stringify(venueUser))
          dispatch({
            type: CHANGE_CHECK_IS_NOT_VIRTUAL,
            payload: venueUser
          })
        }

        return
      }
      cookies.remove('user', { path: '/' })
      cookies.remove("userRestrictions", { path: "/" });
      localStorage.clear()
      dispatch({ type: LOGOUT })
    })
    .catch((err) => {
      console.log(err)
      cookies.remove('user', { path: '/' })
      cookies.remove("userRestrictions", { path: "/" });
      // localSts
      localStorage.clear()
      dispatch({ type: LOGOUT })
    })
}


export const changeCountryId = (payload) => (dispatch) => {
  dispatch({
    type: SET_COUNTRY_ID,
    payload,
  });
};

export const changeCheckApplySurvey = (survey) => ({
  type: CHANGE_CHECK_APPLY_SURVEY,
  payload: survey
});

export default authModule

