import React, { useMemo } from 'react'

//Colors
import Theme from 'utils/themes/Theme'
import { ThemeProvider } from '@material-ui/core/styles'
//Redux
import { connect } from 'react-redux'

const CustomThemeProvider = ({ children, userType }) => {
  const nameTheme = useMemo(() => {
    if (typeof userType === 'number') {
      switch (userType) {
        case 1:
          return 'defaultTheme'
        case 2:
        case 3:
        case 7:
        case 8:
        case 9:
        case 10:
        case 15:
          return 'medicalTheme'
        case 4:
        case 5:
        case 190:
        case 23:
        case 28:
        case 38:
        case 179:
        case 180:
        case 181:
        //case 29:
        //case 30:
          return 'trainerTheme'
        case 6:
        case 14:
        case 183:
          return 'counterTheme'
        case 16:
        case 41:
        case 158:
        case 152:
        case 154:
        case 156:
        case 156:
        case 168:
        case 171:
        case 164:
        case 160:
        case 187:
        case 186:
        case 189:
        case 40:
        case 187:
        case 21:
        case 173:
        case 172:
        case 188:
        case 175:
          return 'ComercialTheme'
        case 176:
          return 'ComercialTheme'
        case 174:
          return 'ComercialTheme'
        case 25:
        case 29:
        case 30:
        case 37:
        case 39:
        case 153:
          return 'VirtualTheme'
        default:
          return 'defaultTheme'
      }
    }
  }, [userType])

  return <ThemeProvider theme={Theme[nameTheme]}>{children}</ThemeProvider>
}

const mapStateToProps = ({ auth }) => ({
  userType: auth.userType
})

export default connect(mapStateToProps)(CustomThemeProvider)
