import _ from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiInstances } from 'services/instance';
import { vsprintf } from 'sprintf-js';
import { toCanonical } from 'utils/string';

/**
 * @typedef DefaultContex
 * @type {object}
 */

/**
 * @typedef RelationalContex
 * @type {object}
 * @property {array} fkIds - fkIds relacionados
 * @property {array} fkLoadedIds - fkIds cargados
 */

/**
 * @typedef AsyncContex
 * @type {object}
 * @property {string} term - Valor buscado
 * @property {Object[]} searches - Listado de busquedas
 * @property {number} searches[].expirationDate - Tiempo de expiración en UNIX
 * @property {string} searches[].status - Estado de la búsqueda (idle, loading, success, error)
 * @property {string} searches[].termCanonical - Termino buscado en minúsculas y sin acentos
 */

/**
 * @typedef ListOptions
 * @type {Object[]}
 * @property {boolean} async - Define si la carga de datos es asíncrona
 * @property {DefaultContex} context - El contexto para los diferentes tipos de listado de opciones
 * @property {string} typeId - Id unico del listado de opciones
 * @property {string} instanceName - Nombre del API
 * @property {string} keyLabel - Nombre del key que se usara para mostrar
 * @property {string} keyValue - Nombre del key que se usara para comparar
 * @property {Object[]} options - Opciones de listado
 * @property {array} params - Parámetros GET que se enviara al servicio del API
 * @property {func} renderOption - Función encargada de personalizar de como se debe mostrar la opción en el listado
 * @property {string} serviceId - Path del servicio del API que traerá los datos
 * @property {string} status - Estado del listado (idle, loading, success y error)
 * @property {OptionListTypes} type - Tipo de listado (relational, other)
 * @property {number} durationTime - Tiempo de vigencia de los datos una vez sean cargados (formato UNIX)
 */

/**
 * @typedef InitialState
 * @type {object}
 * @property {ListOptions} options - 
 */

/** @typedef {'relational'} OptionListTypes */



/**
 * Nombre del stado en redux
 */
const stateName = 'listsOptions';

/**
 * Enum para los tipos de listado de opciones
 * @readonly
 * @enum {string}
 */
export const optionListTypes = {
    relational: 'relational'
}

/**
 * Retorna el estado local
 * 
 * @param {Object} state 
 * @returns {Object}
 */
const localState = (state) => state[stateName]


/**
 * Devuelve el index de un listado de opciones buscado por el typeId
 * 
 * @param {Array} options 
 * @param {string} typeId 
 * @returns {Object}
 */
const getIndexOption = (options, typeId) => _.findIndex(options, { typeId })

/**
 * Verifica si un término se puede buscar en el servidor remoto
 * 
 * @param {Object} optionAsync 
 * @param {Object[]} optionAsync.searches - Busquedas realizadas
 * @param {string} optionAsync.term - Palabra a buscar
 * @returns {boolean}
 */
const canSearchAsync = (optionAsync) => {
    const { searches, term } = optionAsync.context;
    return (
        term &&
        (_.findIndex(searches, searched => (
            (searched.termCanonical === toCanonical(term)) &&
            (searched.status !== 'idle' && searched.expirationDate <= Math.floor(Date.now() / 1000))
        )) < 0)
    );
}


/**
 * Retorna los listados de opciones que se pueden procesar 
 * para cargar y refrescar datos
 * 
 * @param {Object} localState 
 * @param {array|null} typeIds 
 * @returns {array}
 */
const getOptionsForLoad = (localState, typeIds) => {
    return localState.options.filter(listOptions => {
        let isValid = true;
        const { async, status, type, typeId } = listOptions;

        isValid = isValid && (typeIds === null || typeIds.includes(typeId));
        isValid = isValid && ([optionListTypes.relational].includes(type) || async || status === 'idle');
        isValid = isValid && (!async || canSearchAsync(listOptions));

        return isValid;
    });
}

/**
 * Procesa un path insertándole variables (term y fkId)
 * 
 * @param {string} serviceId 
 * @param {string} term 
 * @param {array|null} fkId 
 * @returns {string}
 */
const processServiceId = (serviceId, term = "", fkId = null) => {
    let newServiceId = serviceId.replace(/:term/g, (term || ""));
    if (fkId !== null) {
        newServiceId = vsprintf(newServiceId, fkId);
    }
    return newServiceId;
}

/**
 * Procesa las opciones ingresadas y las combina con las opciones actuales
 * Elimina las opciones locales que sean iguales que las remotas con el keyValue
 * Inserta las nuevas opciones
 * Actualiza el contexto
 * - Si es asíncrono, entonces actualiza el estado de las búsquedas 
 * - Si es relacional, inserta las fkIds que fueron cargadas
 * 
 * @param {ListOptions} listOptions 
 * @param {Object[]} newOptions 
 * @param {*} fkId 
 * @returns {Object}
 */
const procesNewListOptions = (listOptions, newOptions, fkId = null) => {
    const { async, context, durationTime, keyValue, options } = listOptions;
    const termCanonical = toCanonical(context.term);


    return {
        ...listOptions,
        options: [
            ...options.filter(option => newOptions.findIndex(newOption => newOption[keyValue] === option[keyValue])),
            ...newOptions
        ],
        status: 'success',
        context: {
            ...context,
            ...(async ? {
                searches: [
                    ...context.searches.filter(searched => searched.termCanonical !== termCanonical),
                    {
                        expirationDate: Math.floor(Date.now() / 1000) + durationTime,
                        status: 'success',
                        termCanonical
                    }
                ]
            } : {}),
            ...(fkId ? { fkLoadedIds: [...context.fkLoadedIds, fkId] } : {})
        },
    }
}

/**
 * Realiza la petición a la API y procesa la información recibida
 * @param {ListOptions} listOptions  
 * @param {number[]|null} fkId 
 * @param {func} processRow 
 * @returns {ListOptions[]}
 */
const getRemoteOptions = async (listOptions, fkId = null, processRow = v => v) => {
    const { context, instanceName, params, serviceId } = listOptions;
    const termCanonical = toCanonical(context.term);
    const newServiceId = processServiceId(serviceId, termCanonical, fkId);
    let response = null;

    try {
        response = await apiInstances[instanceName].get(newServiceId, params);
        const newOptions = response.data.map(processRow);
        return procesNewListOptions(listOptions, newOptions, fkId)
    } catch (error) {
        return ({ ...listOptions, status: 'error' })
    }
}


/**
 * 
 * Función de carga de valores para el atributo options.options
 * solo carga si el status es igual a idle
 * Si se establece un conjunto de ids, solo va a procesar esos listados de opciones
 * de lo contrario procesará todos los listados de opciones
 */
export const loadListOptionsAsync = createAsyncThunk(
    'list/options',
    async (typeIds, thunkAPI) => {
        const _localState = localState(thunkAPI.getState());
        const newTypeIds = Array.isArray(typeIds) && typeIds.length ? typeIds : null;
        const listsOptions = getOptionsForLoad(_localState, newTypeIds);
        const newListOptions = [];

        for (const listOptions of listsOptions) {


            const { context } = listOptions;
            switch (listOptions.type) {
                case optionListTypes.relational:
                    for (const fkId of context.fkIds) {
                        if (listOptions.async || !context.fkLoadedIds.includes(fkId)) {
                            thunkAPI.dispatch(setStatusLoading(listOptions.typeId));
                            newListOptions.push(await getRemoteOptions(listOptions, fkId, (newOption => {
                                const oldOption = listOptions.options.find(option => option[listOptions.keyValue] === newOption[listOptions.keyValue])
                                const oldFkIds = (oldOption?.__fkId || []).filter(__fkIdRow => !_.isEqual(fkId, __fkIdRow));
                                return {
                                    ...newOption,
                                    __fkId: [
                                        ...oldFkIds,
                                        fkId
                                    ],
                                }
                            })))
                        }
                    }
                    break;
                default:
                    thunkAPI.dispatch(setStatusLoading(listOptions.typeId));
                    newListOptions.push(await getRemoteOptions(listOptions))
                    break;
            }
        }
        return newListOptions;
    },
    {
        condition: (ids, { getState }) => {
            const newIds = Array.isArray(ids) && ids.length ? ids : null;
            const finded = localState(getState()).options.filter(listOptions => (
                listOptions.status !== 'loading' &&
                (listOptions.status === 'idle' || (listOptions.async)) &&
                (newIds === null || newIds.includes(listOptions.typeId))
            ))

            return finded.length > 0;
        }
    }
);


/** @type {InitialState} */
export const initialState = {
    options: []
};

/**
 * Retorna el contexto iniciar a partir del tipo de listado y si es o no asíncrono
 * 
 * @param {Object} param0 
 * @param {string} param0.type
 * @param {boolean} param0.async
 * @returns 
 */
export const initialContextByType = ({ type, async }) => {
    /** @type {DefaultContex|RelationalContex|AsyncContex} */
    let context = {};


    switch (type) {
        case 'relational':
            context = { fkIds: [], fkLoadedIds: [] }
            break;
        default:
            break;
    }
    if (async) {
        context = {
            ...context,
            term: "",
            searches: []
        }
    }
    return context;
}


export const listsOptionsSlice = createSlice({
    name: 'listsOptions',
    initialState,
    reducers: {
        //carga un listado de opviones sin valores
        //y quedaria pendiente por procesar por la funcion loadListOptionsAsync
        addListOptions: (state, action) => {
            const { async, context, typeId, instanceName, keyLabel, keyValue, options, params, renderOption, serviceId, status, type, durationTime } = action.payload;
            const indexOptions = getIndexOption(state.options, typeId);
            if (indexOptions === -1) {
                state.options = [
                    ...state.options,
                    { async, context: { ...initialContextByType({ type, async }), ...context }, typeId, instanceName, keyLabel, keyValue, options: [], params, renderOption, serviceId, status: 'idle', type, durationTime }
                ]
            }
        },
        updateListOptions: (state, action) => {
            const listOptions = action.payload;
            const indexOptions = getIndexOption(state.options, listOptions.typeId);
            const newOption = _.pickBy(action.payload, function (value, key) {
                return ['context', 'typeId', 'instanceName', 'keyLabel', 'keyValue', 'params', 'renderOption', 'serviceId'].includes(key);
            });
            if (indexOptions !== -1) {
                state.options[indexOptions] = {
                    ...state.options[indexOptions],
                    ...newOption
                }
            }
        },
        setContextTerm: (state, action) => {
            const { typeId, term } = action.payload;
            const indexOptions = getIndexOption(state.options, typeId);

            if (indexOptions !== -1) {
                state.options[indexOptions] = {
                    ...state.options[indexOptions],
                    context: {
                        ...state.options[indexOptions].context,
                        term,
                    }
                }
            }
        },
        setStatusLoading: (state, action) => {
            const typeId = action.payload;
            const indexOptions = getIndexOption(state.options, typeId);
            if (indexOptions !== -1) {
                state.options[indexOptions].status = 'loading'
            }
        }
    },
    extraReducers: (builder) => {
        builder
            //Carga los listado de opciones actualizados
            //por la funcion loadListOptionsAsync
            .addCase(loadListOptionsAsync.fulfilled, (state, action) => {
                action?.payload?.forEach(optionUpdate => {
                    const indexOptions = getIndexOption(state.options, optionUpdate.typeId);
                    if (indexOptions !== -1) {
                        state.options[indexOptions] = optionUpdate;
                    }
                })
            })
    },
});

export const { addListOptions, updateListOptions, setContextTerm, setStatusLoading } = listsOptionsSlice.actions;
//selectors
export const selectListsOptions = (state) => state;
export const selectListOptionsById = (state, typeId) => _.find(localState(state).options, { typeId });


export const selectContext = (state, typeId) => _.find(localState(state).options, { typeId })?.context;




export default listsOptionsSlice.reducer;