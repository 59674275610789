// Icons
import CloseIcon from '@material-ui/icons/Close'

import { ArrowLeftIcon, IconDelete } from 'assets/icons/customize/config'

// UI
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import { Box } from '@material-ui/core'

export const ShardComponentModal = ({
  title = '',
  body,
  isOpen,
  handleClose,
  viewButtonClose = true,
  width = 'md',
  fullWidth = false,
  is_fullWidth,
  backgroundColorButtonClose,
  colorButtonClose = 'black',
  style,
  paperStyle,
  isEdit = false,
  handleDeleteprice = () => null,
  resultFind = [],
  hideBackdrop,
  disableTransition,
  dialogWidth,
  notFullWidth = false,
  buttonCloseVisable = true,
  buttonBackVisable = false
}) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      maxWidth={width}
      fullScreen={is_fullWidth}
      hideBackdrop={hideBackdrop}
      TransitionProps={{ timeout: disableTransition ? 0 : 1 }}
      PaperProps={{
        style: paperStyle
      }}
    >
      <div style={style}>
        {title &&
          <DialogTitle id="simple-dialog-title">
            { buttonBackVisable
              ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box onClick={handleClose} style={{ marginRight: '16px', height: '24px', display: 'flex', alignItems: 'end' }} ><ArrowLeftIcon /></Box> <span>{title}</span>
              </div>)
              : (title)}
          </DialogTitle>
        }
        { handleClose && viewButtonClose
          ? (
          <>
            {isEdit && (
              <IconButton
                aria-label="close"
                style={{
                  position: 'absolute',
                  borderRadius: 0,
                  right: 58,
                  top: 6,
                  color: colorButtonClose
                }}
                onClick={() => handleDeleteprice(resultFind)}
              >
                <IconDelete />
              </IconButton>
            )}

            { buttonCloseVisable && (<IconButton
              aria-label="close"
              style={{
                position: 'absolute',
                borderRadius: 0,
                right: 10,
                top: 6,
                color: colorButtonClose
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>) }
          </>
            )
          : null}
        <DialogContent style={{ width: notFullWidth ? '100%' : dialogWidth }}>
          {body}
        </DialogContent>
      </div>
    </Dialog>
  )
}
