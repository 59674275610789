import ControlledSelectAutocomplete from "components/Shared/ControlledSelectAutocomplete/ControlledSelectAutocomplete";
import { setData } from "./utils";

export const UPDATE_PRODUCTS = "quotations/UPDATE_PRODUCTS";
export const ADD_USER = "quotations/ADD_USER";
export const SET_USERS = "quotations/SET_USERS";
export const UPDATE_USER = "quotations/UPDATE_USER";
export const DELETE_USER = "quotations/DELETE_USER";
export const SET_USER_EXPANDED = "quotations/SET_USER_EXPANDED";
export const CLEAR_USERS = "quotations/CLEAR_USERS";
export const IS_PROPOSAL = "quotations/IS_PROPOSAL";
export const IS_SALE_NEW = "quotations/IS_SALE_NEW";
export const SET_PRODUCT_SELECTED = "quotations/SET_PRODUCT_SELECTED";
export const SET_COUPON_BUDGET = "quotations/SET_COUPON_BUDGET";
export const ADD_PRODUCT_GROUP = "quotations/ADD_PRODUCT_GROUP";
export const SET_PRODUCT_EXPANDED = "quotations/SET_PRODUCT_EXPANDED";
export const UPDATE_PRODUCT_GROUP = "quotations/UPDATE_PRODUCT_GROUP";
export const SET_PRODUCTS_GROUP = "quotations/SET_PRODUCTS_GROUP";
export const SET_MODE_MEMBER = "quotations/SET_MODE_MEMBER";
export const DELETE_PRODUCT_GROUP = "quotations/DELETE_PRODUCT_GROUP";
export const SET_USER_TO_PAY = "quotations/SET_USER_TO_PAY";
export const SET_MODE_NEW_USER_PAY = "quotations/SET_MODE_NEW_USER_PAY";
export const CLEAR_USER_TO_PAY = "quotations/CLEAR_USER_TO_PAY";
export const SET_USER_MEMBER = "quotations/SET_USER_MEMBER";
export const SET_DISCOUNT = "quotations/SET_DISCOUNT";
export const CLEAR_MANUAL_DISCOUNT = "quotations/CLEAR_MANUAL_DISCOUNT";
export const SET_IS_CUSTOMER = "quotations/SET_IS_CUSTOMER";
export const SET_DEAL = "quotations/SET_DEAL";

export const initialState = {
  selectedProducts: [],
  selectedUsers: [], //listado de usuarios con productos
  selectedGroupProducts: [], //listado de productos grupales
  currentUserExpanded: {},
  currentProductExpanded: null, //producto seleccionado grupal
  isProposal: 0,
  isSaleNew: 0,
  currentProductSelected: null, //producto 2x1
  couponBudget: [], //cupon al total
  addMemberMode: 0, //indicador si desea agregar el prospecto en un grupal o como un usuario normal
  addNewUserPayMode: 0, //indicador si desea agregar el usuario como nueva persona responsable de pago.
  userToPay: null, //usuario responsable del pago
  userMember: null, //nuevo member de la factura
  total_discount: 0, //total_discount manual de la factura
  isForAddCustomer: false, // indicador si se accede al form afiliate lead para cambiar el customer
  deal: null //current deal quotation
};

const quotationsModule = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTS:
      return {
        ...state,
        selectedProducts: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        selectedUsers: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        selectedUsers: [...state.selectedUsers, action.payload],
      };
    case UPDATE_USER:
      return {
        ...state,
        selectedUsers: state.selectedUsers.map((user) =>
          user.id === action.payload.id ? { ...user, ...action.payload } : user
        ),
      };
    case DELETE_USER:
      return {
        ...state,
        selectedUsers: state.selectedUsers.filter(
          (user) => user.id !== action.payload.id
        ),
      };
    case SET_USER_EXPANDED:
      return {
        ...state,
        currentUserExpanded: action.payload,
      };
    case SET_PRODUCT_EXPANDED:
      return {
        ...state,
        currentProductExpanded: action.payload,
      };
    case CLEAR_USERS:
      return {
        ...state,
        currentUserExpanded: {},
        selectedUsers: [],
        selectedGroupProducts: [],
      };
    case IS_PROPOSAL:
      return {
        ...state,
        isProposal: action.payload,
      };
    case IS_SALE_NEW:
      return {
        ...state,
        isSaleNew: action.payload,
      };
    case SET_COUPON_BUDGET:
      return {
        ...state,
        couponBudget: action.payload,
      };
    case SET_PRODUCT_SELECTED:
      return {
        ...state,
        currentProductSelected: action.payload,
      };
    case ADD_PRODUCT_GROUP:
      return {
        ...state,
        selectedGroupProducts: [...state.selectedGroupProducts, action.payload],
      };
    case SET_PRODUCTS_GROUP:
      return {
        ...state,
        selectedGroupProducts: action.payload,
      };
    case UPDATE_PRODUCT_GROUP:
      return {
        ...state,
        selectedGroupProducts: state.selectedGroupProducts.map((product) =>
          product.id_uuid === action.payload.id_uuid
            ? { ...product, ...action.payload }
            : product
        ),
      };
    case SET_MODE_MEMBER:
      return {
        ...state,
        addMemberMode: action.payload,
      };
    case SET_MODE_NEW_USER_PAY:
      return {
        ...state,
        addNewUserPayMode: action.payload,
      };
    case DELETE_PRODUCT_GROUP:
      return {
        ...state,
        selectedGroupProducts: state.selectedGroupProducts.filter(
          (product) => product.id_uuid !== action.payload.id_uuid
        ),
      };
    case SET_USER_TO_PAY:
      return {
        ...state,
        userToPay: action.payload,
      };
    case CLEAR_USER_TO_PAY:
      return {
        ...state,
        userToPay: null,
      };
    case SET_USER_MEMBER:
      return {
        ...state,
        userMember: action.payload,
      };
    case SET_DISCOUNT:
      return {
        ...state,
        total_discount: action.payload,
      };
    case CLEAR_MANUAL_DISCOUNT:
      return {
        ...state,
        total_discount: 0,
      };
    case SET_IS_CUSTOMER:
      return {
        ...state,
        isForAddCustomer: action.payload,
      };
    case SET_DEAL:
      return {
        ...state,
        deal: action.payload,
      };
    default:
      return state;
  }
};

export const updateSelectedProducts = (payload) =>
  setData(UPDATE_PRODUCTS, payload);

export const addUserInBudget = (payload) => setData(ADD_USER, payload);
export const setUsersInBudget = (payload) => setData(SET_USERS, payload);
export const updateUserInBudget = (payload) => setData(UPDATE_USER, payload);
export const deleteUserInBudget = (payload) => setData(DELETE_USER, payload);
export const clearUsers = (payload) => setData(CLEAR_USERS, payload);
export const changeStatusProposal = (payload) => setData(IS_PROPOSAL, payload);
export const setIsSaleNew = (payload) => setData(IS_SALE_NEW, payload);
export const setUserToAddProducts = (payload) =>
  setData(SET_USER_EXPANDED, payload);
export const setProductSelected = (payload) =>
  setData(SET_PRODUCT_SELECTED, payload);
export const setCouponBudget = (payload) => setData(SET_COUPON_BUDGET, payload);
export const setProductGroup = (payload) => setData(ADD_PRODUCT_GROUP, payload);
export const setProductsGroupInBudget = (payload) =>
  setData(SET_PRODUCTS_GROUP, payload);
export const setProductExpanded = (payload) =>
  setData(SET_PRODUCT_EXPANDED, payload);
export const updateProductGroupInBudget = (payload) =>
  setData(UPDATE_PRODUCT_GROUP, payload);
export const setAddMemberMode = (payload) => setData(SET_MODE_MEMBER, payload);
export const setAddUserPayMode = (payload) =>
  setData(SET_MODE_NEW_USER_PAY, payload);
export const deleteProductGroup = (payload) =>
  setData(DELETE_PRODUCT_GROUP, payload);
export const setUserToPay = (payload) => setData(SET_USER_TO_PAY, payload);
export const setUserMember = (payload) => setData(SET_USER_MEMBER, payload);
export const clearUserToPay = (payload) => setData(CLEAR_USER_TO_PAY, payload);
export const setTotalDiscount = (payload) => setData(SET_DISCOUNT, payload);
export const clearManualDiscount = (payload) =>
  setData(CLEAR_MANUAL_DISCOUNT, payload);
export const setIsForAddCustomer = (payload) =>
  setData(SET_IS_CUSTOMER, payload);
export const setDeal = (payload) =>
  setData(SET_DEAL, payload);
export default quotationsModule;
