import { createSlice } from '@reduxjs/toolkit';

// const localState = (state, key = null) => {
//     return key ? state[stateName][key] : state[stateName];
// }

export const initialState = {
    status: false
};

export const sellingSlice = createSlice({
    name: 'selling',
    initialState,
    reducers: {
        setStatuss: (state, action) => {
            state.status = action.payload;
        }
    },
});

export const { setStatuss } = sellingSlice.actions;

export default sellingSlice.reducer;