import { profileService } from "./auth";
import {
  axiosInstance,
  axiosInstanceGeneralConfig,
  axiosInstanceReservation,
  axiosInstanceWebPage,
} from "./instance";

export const getCitis = () => {
  return axiosInstance.get("microServices/cities");
};

export const getVenuesForNear = (value) => {
  return axiosInstance.post("lupap/venuesNear", value);
};

export const getVenues = () => {
  return axiosInstance.get("microServices/venue");
};

export const getCountries = () => {
  return axiosInstanceGeneralConfig.get("country/all");
};

export const getListActivitiesHasProduct = (venue, activity) => {
  return axiosInstanceGeneralConfig.get(`activity/activityhasproduct/${venue}/${activity}`);
};

export const getEmployeesGeneral = (
  idVenue,
  filter = null,
  brandId = null,
  virtual = null
) => {
  return axiosInstanceReservation.get(
    `employeeVenue/venue/${idVenue}?filter=${filter}&brandId=${brandId}&virtual=${virtual}`
  );
};

export const getListAllEmployeePag = (venue, page, limit, filter, status, rol) => {
  const parseFilter = filter ? `&filter=${filter}` : '';
  const statusRol = rol ? rol.map((e, i) => `&rol_id[${i}]=${e}`).join() : '';

  return axiosInstanceReservation.get(`employeeVenue/allEmployeesPag/${venue}?page=${page}&limit=${limit}&status=${status}${parseFilter}${statusRol}`);
};

export const activateInActivateUser = (id, activate = true) => {
  const path = activate ? 'collaborator/activateCollaborator' : 'collaborator/desactivate';
  return axiosInstanceWebPage.post(`${path}/${id}`, { id });
};

export const loginToken = async (data, headers, resolve, reject, otpLogin = false) => {
  localStorage.setItem("access_token", data?.access_token);
  localStorage.setItem("refresh_token", data?.refresh_token);
  localStorage.setItem(
    "organization_id",
    headers["x-bodytech-organization"]
  );
  localStorage.setItem("brands", headers["x-bodytech-brand"]);

  const brandId = JSON.parse(headers["x-bodytech-brand"])[0].brand_id;
  const companyId = JSON.parse(headers["x-bodytech-brand"])[0].uuid_company;

  localStorage.setItem("brand_id", brandId);
  localStorage.setItem("company_id", companyId);

  //URL MYBT
  const resultProfile = await profileService();
  console.log(resultProfile)
  if (
    resultProfile &&
    resultProfile.data &&
    resultProfile.data.status !== "error"
  ) {
    resultProfile.data.data.companyId = headers["x-bodytech-company"];
    resultProfile.data.data.companyOrganization =
      headers["x-bodytech-organization"];
    resultProfile.data.data.brands = JSON.parse(
      headers["x-bodytech-brand"]
    );
    resultProfile.data.data.brandId = brandId;
    resultProfile.data.data.companyId = companyId;
    if (otpLogin) {
      return resultProfile.data.data;
    }
    resolve(resultProfile.data);
  } else {
    if (otpLogin) {
      return "Usuario o contraseña inválida";
    }
    reject("Usuario o contraseña inválida");
  }
}
