import { ShardComponentModal } from 'components/Shared/Modal/Modal'
import { axiosInstance, axiosInstanceWebPage, axiosInstanceProducts } from './instance'
import { loginToken } from './utils'

const loginService = (user, pass, setIsViewOtp, setUuidUser = () => {}) => {
  return new Promise((resolve, reject) => {
    // URL Web
    axiosInstanceWebPage
      .post('/oauth/getTokenMyBodyTechAction', {
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID_LOGIN,
        client_secret: process.env.REACT_APP_CLIENT_SECRET_LOGIN,
        uuid_front: process.env.REACT_APP_CLIENT_SECRET_UUID_FRONT_MYBT,
        username: user,
        password: pass
      })
      .then(async ({ data, headers }) => {
        if (data?.data?.uuid) {
          setIsViewOtp(true)
          setUuidUser(data.data.uuid)
          return true
        }
        if (data && data.access_token) {
          await loginToken(data, headers, resolve, reject)
        } else {
          reject('Usuario o contraseña inválida')
        }
      })
      .catch((error) => {
        reject(error?.error_description || error?.message || 'Usuario o contraseña inválida')
      })
  })
}

const validationOtp = (data) => {
  return axiosInstanceWebPage.post('oauth/otpTokenMyBodyTech', data)
}

const getResendOtpTokenMyBodyTech = (data) => {
  return axiosInstanceWebPage.post('oauth/resendOtpTokenMyBodyTech', data)
}

const profileService = () => {
  return axiosInstance.post('authentication/profile')
}

const changePasswordAfiliate = (value) => {
  return axiosInstanceWebPage.post('user/change-password', value)
}

const changePasswordCollaborator = (value) => {
  return axiosInstanceWebPage.post('user-collaborators/change-password', value)
}

const signUpService = () => { }

const logoutService = () => { }

const authUsersDW = (id, documentType, brandId = 1) => {
  return axiosInstanceWebPage.get(`getUserDw/${id}/${documentType}/${brandId}`)
}

const getRatingUser = (data) => {
  return axiosInstanceProducts.post(`crm-report/get-rating-user`, data)
}

const saveRatingUser = (data) => {
  return axiosInstanceProducts.post(`crm-report/save-rating-user`, data)
}

export {
  loginService,
  signUpService,
  profileService,
  logoutService,
  authUsersDW,
  changePasswordAfiliate,
  changePasswordCollaborator,
  validationOtp,
  getResendOtpTokenMyBodyTech,
  getRatingUser,
  saveRatingUser
}
