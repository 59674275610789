import { axiosInstanceProducts } from "services/instance";

export const createPromotionProfile = (data) => {
  return axiosInstanceProducts.post(`promotion-profiles`, data);
};

export const updatePromotionProfile = (id, data) => {
  return axiosInstanceProducts.put(`promotion-profiles/${id}`, data);
};

export const updateStatusPromotionProfile = (id, status) => {
  return axiosInstanceProducts.put(`promotion-profiles/${id}/status/${+status}`);
};

export const getAllProfilings = (params = {}, options) => {
  return axiosInstanceProducts.get('promotion-profiles/pag', {
    params,
    ...options,
  });
};

export const getProfilingById = (id) => {
  return axiosInstanceProducts.get(`promotion-profiles/id/${id}`);
};

export const deleteProfilingById = (id) => {
  return axiosInstanceProducts.delete(`promotion-profiles/id/${id}`);
};

export const createProfilingCloseList = (data) => {
  return axiosInstanceProducts.post(`promotion-profiles/create-close-list`, data);
};

export const createProfilingCloseListMassive = (id, data) => {
  return axiosInstanceProducts.post(`promotion-profiles/create-close-list-massive?promotion=${id}`, data);
};

export const getUsersByProfile = (id, limit = 10, page = 1, filter) => {
  let mapFilter = "";
  if(filter?.document_number) mapFilter += `&document_number=${filter?.document_number}`;

  return axiosInstanceProducts.get(`promotion-profiles/get-users-by-profile/${id}?limit=${limit}&page=${page}${mapFilter}`);
};

export const addUserProfiler = (id, data) => {
  return axiosInstanceProducts.post(`promotion-profiles/add-user-close-list/${id}`, data);
};

export const deleteUserProfile = (id, document, document_type) => {
  return axiosInstanceProducts.delete(`promotion-profiles/delete-user-by-profile/${id}?document_number=${document}&document_type=${document_type}`);
};

export const userByProfiling = (id, document, document_type) => {
  return axiosInstanceProducts.get(`promotion-profiles/user-by-profile/${id}?document_number=${document}&document_type=${document_type}`);
};
