export const routesUrl = {
  base: '/',
  baseAuth: '/auth',
  login: '/auth/login',
  baseApp: '/',
  home: '/home'
}

export const ConfigNameRoutes = {
  config: '/config',
  products: '/products',
  promotions: '/promotions',
  paymentLink: '/payment-link',
  unformalizedPaymentLink: '/unformalized-payment-link',
  calendarJourney: '/calendar-journey',
  dashboardJourney: '/dashboard-journey',
  partnersJourney: '/partners-journey',
  partnersJourneyCoach: '/partners-journey/:user_id/:role',
  quotation: '/quotation',
  quotationConfig: '/quotation-config/:quotation_id?',
  invoiceConfig: '/invoice-config/:invoice_id',
  nutrition: '/nutrition/:user_id/:plan_id?',
  carterization: '/carterization',
  commissions: '/commissions',
  reports: '/reports',
  configModules: '/config-modules',
  configClinicHistory: '/config-clinic-history',
  configReservations: '/config-reservations',
  configProducts: '/config-products',
  configRoulette: '/config-roulette',
  createTraining: '/create-plan-training',
  configJourneys: '/config-journey',
  createTrainingForAfiliate:
    '/create-plan-training-for-afiliate/:document_number',
  manageTraining: '/manage-training',
  createSession: '/create-session',
  updateSession: '/update-session/:sessionId',
  updateSessionRoute: '/update-session/',
  listSessions: '/manage-training/sessions',
  quotes: '/quotes',
  counter: '/counter',
  collaborators: '/collaborators',
  diaryGeneral: '/diary-general',
  groupActivityDetail: '/group-activity-detail/:activity_id',
  locations: '/locations',
  afiliates: '/afiliates',
  afiliatesTrainer: '/afiliates-trainer',
  medicalLeads: '/medicalLeads',
  activitiesCalendar: '/activities-calendar',
  exercices: 'manage-training/exercises',
  configTrainingPlan: '/config-training-plan',
  homeTraining: '/home',
  createMedicalProfessional: '/create-medical-professional',
  ConfigProfessional: '/config-users',
  detailAfiliate: '/detail-afiliate/:id',
  detailAfiliateComercial: '/detail-afiliate/:id/:is_comercial',
  detailAfiliateComercialItem: '/detail-afiliate/:id/:is_comercial/:item',
  detailAfiliateComercialItemUserType:
    '/detail-afiliate/:id/:is_comercial/:item/:userTypeMemCus',
  detailCustomer: '/customer/:id',
  detailCustomerComercial: '/customer/:id/:is_comercial',
  detailCustomerComercialItem: '/customer/:id/:is_comercial/:item',
  recipes: '/recipes',
  maximunCapacity: '/maximum-capacity',
  detailVirtualAfiliate:
    '/detail-virtual-afiliate/:user_id/:quote_id/:quote_type?',
  virtualAfiliates: '/virtual-afiliates',
  trainerBooking: '/trainer-booking',
  agents: '/agents',
  tickets: '/tickets',
  configTickets: '/config-tickets',
  flow: '/flow',
  coupons: '/coupons',
  profiling: '/profiling',
  configFlow: '/config-flow',
  cashier: '/cashier',
  corporative: '/corporative',
  companies: '/companies',
  campaigns: '/campaigns',
  deals: '/deals',
  dealsDetail: '/deals-detail',
  crm: '/crm',
  leads: '/leads',
  novelty: '/novelty',
  kits: '/kits',
  chat: '/chat',
  generalList: '/general-list',
  generalListVirtual: '/general-list-virtual',
  generalListVirtualUser: '/general-list-virtual-user',
  generalListVirtualActive: '/general-list-virtual-active',
  generalListNotCarterized: '/general-list-no-carterized',
  listAgreementLinesNotTokenized: '/list-agreement-lines-not-tokenized',
  customersList: '/customers-list',
  facturation: '/facturation',
  ranking: '/ranking',
  unsignedContracs: '/unsigned-contracts',
  noValidatedPaymentMethods: '/noValidatedPayment',
  listCorporate: '/listCorporate',
  massiveCarterization: '/massive-carterization',
  reportsByVenue: '/reports-venue',
  referralsNotAplicateList: '/referrals-not-aplicate-list',
  crmConfiguration: '/config-crm',
  CommercialStatistics: '/commercial-statistics',
  CreateProfilingCloseList: '/create-profiling-close-list',
  UpdateProfilingCloseList: '/update-profiling-close-list/:id',
  BlackList: '/black-list',
  promotionServices: '/promotion-serives',
  NotificationPush: '/notification-push',
  configSpaces: '/config-spaces',
  dashboardTrafic: '/dashboard',
  TokenizationList: '/tokenizations-list',
  shoppingExpress: '/shopping-express',
  createShoppingExpress: '/create-shopping-express',
  shoppingExpressId: '/shopping-express/:id'
}
